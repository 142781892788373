@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
}

#root {
  flex: 1;
}

.pr-dataviewer__container {
  display: flex;
  min-height: 100vh;
}
.data-grid-container {
  background: var(--table-container-background-color);
  flex: 1;
}
.pr-dataviewer {
  background: var(--table-background-color);
}

/* Columns */
.data-grid-container .pr-dataviewer.data-grid tr:nth-child(1) td.cell {
  text-align: center;
  font-weight: 700;
}

.data-grid-container .pr-dataviewer.data-grid .cell,
.pr-dataviewer.data-grid .cell > input.data-editor {
  padding: 4px;
}

.pr-dataviewer.data-grid .cell > input.data-editor {
  width: calc(100% - 8px);
}
